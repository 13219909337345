import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _531ebbc6 = () => interopDefault(import('../pages/pac/index.vue' /* webpackChunkName: "pages/pac/index" */))
const _0f8e20b8 = () => interopDefault(import('../pages/pac/manual-subscription.vue' /* webpackChunkName: "pages/pac/manual-subscription" */))
const _3b74e52d = () => interopDefault(import('../pages/pac/operations-to-subscribe.vue' /* webpackChunkName: "pages/pac/operations-to-subscribe" */))
const _acb22912 = () => interopDefault(import('../pages/pac/success.vue' /* webpackChunkName: "pages/pac/success" */))
const _68dba33a = () => interopDefault(import('../pages/payment/down-payments/index.vue' /* webpackChunkName: "pages/payment/down-payments/index" */))
const _1d3625f0 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _e40af536 = () => interopDefault(import('../pages/payment/installments/index.vue' /* webpackChunkName: "pages/payment/installments/index" */))
const _78703db3 = () => interopDefault(import('../pages/payment/preorders/index.vue' /* webpackChunkName: "pages/payment/preorders/index" */))
const _44339a06 = () => interopDefault(import('../pages/payment/select-payment.vue' /* webpackChunkName: "pages/payment/select-payment" */))
const _3e55eb2a = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _85d9ba90 = () => interopDefault(import('../pages/result/error/index.vue' /* webpackChunkName: "pages/result/error/index" */))
const _1a22ff14 = () => interopDefault(import('../pages/result/not-supported-debts.vue' /* webpackChunkName: "pages/result/not-supported-debts" */))
const _ba371730 = () => interopDefault(import('../pages/result/without-debts.vue' /* webpackChunkName: "pages/result/without-debts" */))
const _7f90b5db = () => interopDefault(import('../pages/payment/installments/list.vue' /* webpackChunkName: "pages/payment/installments/list" */))
const _12c163d4 = () => interopDefault(import('../pages/payment/installments/partial.vue' /* webpackChunkName: "pages/payment/installments/partial" */))
const _1b088866 = () => interopDefault(import('../pages/payment/preorders/list.vue' /* webpackChunkName: "pages/payment/preorders/list" */))
const _36aa40a2 = () => interopDefault(import('../pages/payment/preorders/partial.vue' /* webpackChunkName: "pages/payment/preorders/partial" */))
const _733a8983 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/pac",
    component: _531ebbc6,
    name: "pac"
  }, {
    path: "/pac/manual-subscription",
    component: _0f8e20b8,
    name: "pac-manual-subscription"
  }, {
    path: "/pac/operations-to-subscribe",
    component: _3b74e52d,
    name: "pac-operations-to-subscribe"
  }, {
    path: "/pac/success",
    component: _acb22912,
    name: "pac-success"
  }, {
    path: "/payment/down-payments",
    component: _68dba33a,
    name: "payment-down-payments"
  }, {
    path: "/payment/error",
    component: _1d3625f0,
    name: "payment-error"
  }, {
    path: "/payment/installments",
    component: _e40af536,
    name: "payment-installments"
  }, {
    path: "/payment/preorders",
    component: _78703db3,
    name: "payment-preorders"
  }, {
    path: "/payment/select-payment",
    component: _44339a06,
    name: "payment-select-payment"
  }, {
    path: "/payment/success",
    component: _3e55eb2a,
    name: "payment-success"
  }, {
    path: "/result/error",
    component: _85d9ba90,
    name: "result-error"
  }, {
    path: "/result/not-supported-debts",
    component: _1a22ff14,
    name: "result-not-supported-debts"
  }, {
    path: "/result/without-debts",
    component: _ba371730,
    name: "result-without-debts"
  }, {
    path: "/payment/installments/list",
    component: _7f90b5db,
    name: "payment-installments-list"
  }, {
    path: "/payment/installments/partial",
    component: _12c163d4,
    name: "payment-installments-partial"
  }, {
    path: "/payment/preorders/list",
    component: _1b088866,
    name: "payment-preorders-list"
  }, {
    path: "/payment/preorders/partial",
    component: _36aa40a2,
    name: "payment-preorders-partial"
  }, {
    path: "/",
    component: _733a8983,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
